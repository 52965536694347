<template>
  <users-hub-selector
    class="users-hub-filter"
    v-bind="$attrs"
    :formattedValue="formattedSelectedHubs"
    position="is-bottom-left"
    multiple
    v-on="$listeners"/>
</template>

<script>
import { mapGetters } from 'vuex'

const UsersHubSelector = () => import('@components/UsersHubSelector')

export default {
  name: 'users-hub-filter',
  components: {
    UsersHubSelector
  },
  props: UsersHubSelector.props,
  computed: {
    ...mapGetters('hub', [
      'getHubShortCode',
      'hubs'
    ]),
    formattedSelectedHubs() {
      // don't filter if no hubs are selected
      if ([0, this.hubs.length].includes(this.$attrs.value.length)) {
        return this.$t('users.filter.allHubs')
      }
      return this.$attrs.value.map(this.getHubShortCode).join(', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.users-hub-filter {
  display: inline-block;
  max-width: 120px;
}
</style>
